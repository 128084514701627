import {useRef} from "react";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import {HtmlDocument} from "../models/htmlDocument";
import agent from "../api/agent";
import {toast} from "react-toastify";
import {FieldValues, useForm} from "react-hook-form";
import {useAppSelector} from "../store/configureStore";
import {Button} from "@mui/material";
import {useMessageCenterConnectionContext} from "../store/MessageCenterConnectionContext";

interface Props {
  onSignatureOpen: React.Dispatch<React.SetStateAction<boolean>>;
  document: HtmlDocument;
  onInvocationOfTheConnection: () => Promise<void>;
  representativeRoleInContractProgress: undefined;
  setPropertyStatus: React.Dispatch<any>;
  onSaveHandler: () => Promise<void>;
}

export default function CanvasSignature({
  onSignatureOpen,
  document,
  onInvocationOfTheConnection,
  representativeRoleInContractProgress,
  setPropertyStatus,
  onSaveHandler,
}: Props) {
  const {handleSubmit} = useForm();
  const {user} = useAppSelector((state) => state.account);
  const messageCenterConnectionContext = useMessageCenterConnectionContext();

  let padRef = useRef<SignatureCanvas>(null);

  const clear = () => {
    padRef.current?.clear();
  };

  const handleClose = () => {
    onSignatureOpen(false);
  };

  const handleSign = async (data: FieldValues) => {
    console.log(representativeRoleInContractProgress)
    try {
      const signatureUrl = padRef.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png");
      if (signatureUrl) {
        if (user?.profession === "Seller") {
          data.DocumentId = document.id;
          data.SellerSignatureUrl = signatureUrl;
          await agent.Document.signDocument(data).then((response) => {
            if (response !== "") {
              setPropertyStatus(response);
            }
            onSignatureOpen(false);
            messageCenterConnectionContext.connection &&
              onInvocationOfTheConnection();
            toast.success("Document signed successfully");
            onSaveHandler();
          });
          return;
        }
        if (representativeRoleInContractProgress === "SellerRepresentative") {
          data.DocumentId = document.id;
          data.SellerRepresentativeSignatureUrl = signatureUrl;
          await agent.Document.signDocument(data).then((response) => {
            if (response !== "") {
              setPropertyStatus(response);
            }
            onSignatureOpen(false);
            messageCenterConnectionContext.connection &&
              onInvocationOfTheConnection();
            toast.success("Document signed successfully");
            onSaveHandler();
          });
          return;
        }
        if (representativeRoleInContractProgress === "BuyerRepresentative") {
          data.DocumentId = document.id;
          data.BuyerRepresentativeSignatureUrl = signatureUrl;
          await agent.Document.signDocument(data).then((response) => {
            if (response !== "") {
              setPropertyStatus(response);
            }
            onSignatureOpen(false);
            messageCenterConnectionContext.connection &&
              onInvocationOfTheConnection();
            toast.success("Document signed successfully");
            onSaveHandler();
          });
          return;
        }
        data.DocumentId = document.id;
        data.DocumentSignatureUrl = signatureUrl;
        await agent.Document.signDocument(data).then((response) => {
          if (response !== "") {
            setPropertyStatus(response);
          }
          onSignatureOpen(false);
          messageCenterConnectionContext.connection &&
            onInvocationOfTheConnection();
          toast.success("Document signed successfully");
          onSaveHandler();
        });
      }
    } catch {
      toast.error(
        <div>
          Document needs to be approved before you can attach a signature.
        </div>
      );
    }
  };

  return (
    <div style={{textAlign: "center"}}>
      <SignaturePad ref={padRef} canvasProps={{className: "sigCanvas"}} />
      <form onSubmit={handleSubmit(handleSign)}>
        <div className="sigPreview">
          <Button
            type="submit"
            variant="contained"
            sx={{
              background: "#047782",
              "&:hover": {
                background: "#0196A4",
              },
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#fff",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              "&.MuiButtonBase-root:hover": {
                backgroundColor: "rgba(4, 119, 130, 1)",
              },
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={clear}
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              borderRadius: "4px",
              letterSpacing: "1.25px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleClose()}
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              borderRadius: "4px",
              letterSpacing: "1.25px",
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}
