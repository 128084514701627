import {Box, Button, TextField, Typography} from "@mui/material";
import {PropsWithChildren} from "react";
import agent from "../api/agent";
import {toast} from "react-toastify";
import ScheduleConfirmationPerRole from "./ScheduleConfirmationPerRole";
import {useAppSelector} from "../store/configureStore";

interface Props extends PropsWithChildren {
  contractProgressFirstVersionForDateSetter: any;
  confirmDateList: never[];
  onSetFirstTimeSlotConfim: React.Dispatch<React.SetStateAction<boolean>>
  onSetSecondTimeSlotConfirm: React.Dispatch<React.SetStateAction<boolean>>
  onSetThirdTimeSlotConfirm: React.Dispatch<React.SetStateAction<boolean>>
  onSetNotAttendingConfirm: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ConfirmDateTimeAvailability({
  contractProgressFirstVersionForDateSetter,
  confirmDateList, onSetFirstTimeSlotConfim, onSetSecondTimeSlotConfirm, onSetThirdTimeSlotConfirm, onSetNotAttendingConfirm
}: Props) {
  let firstSchedule =
    contractProgressFirstVersionForDateSetter.firstDateTimeSlotForPropertyVisit;
  let secondSchedule =
    contractProgressFirstVersionForDateSetter.secondDateTimeSlotForPropertyVisit;
  let thirdSchedule =
    contractProgressFirstVersionForDateSetter.thirdDateTimeSlotForPropertyVisit;
  const {user} = useAppSelector((state) => state.account);
  if (
    contractProgressFirstVersionForDateSetter.firstDateTimeSlotForPropertyVisit !=
    null
  ) {
    firstSchedule = firstSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }
  if (
    contractProgressFirstVersionForDateSetter.secondDateTimeSlotForPropertyVisit !=
    null
  ) {
    secondSchedule = secondSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }
  if (
    contractProgressFirstVersionForDateSetter.thirdDateTimeSlotForPropertyVisit !=
    null
  ) {
    thirdSchedule = thirdSchedule
      .replace(/T/, " ")
      .replace(/Z/, " ")
      .slice(0, -4);
  }

  const handleConfirmFirstDate = (id: number, date: string) => {
    try {
      agent.ContractProgressDeal.ConfirmFirstDate(id, date);
      toast.success("Date was confirmed successfully");
      onSetFirstTimeSlotConfim(true)
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmSecondDate = (id: number, date: string) => {
    try {
      agent.ContractProgressDeal.ConfirmSecondDate(id, date);
      toast.success("Date was confirmed successfully");
      onSetSecondTimeSlotConfirm(true)
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmThirdDate = (id: number, date: string) => {
    try {
      agent.ContractProgressDeal.ConfirmThirdDate(id, date);
      toast.success("Date was confirmed successfully");
      onSetThirdTimeSlotConfirm(true)
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmNotAttending = (id: number) => {
    try {
      agent.ContractProgressDeal.notAttending(id);
      toast.success("Successfully confirmed that you are unable to attend");
      onSetNotAttendingConfirm(true)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "15px",
      }}
    >
      {(thirdSchedule === null && user?.profession === "PropertySurveyor") ||
      (thirdSchedule === null && user?.profession === "PropertyValuator") ||
      (thirdSchedule === null && user?.profession === "Buyer") ? (
        <>
          <Typography>
            - Schedule is not set, begin by clicking a date.
          </Typography>
          <br />
          <Typography>- Add three dates for availability</Typography>
        </>
      ) : (thirdSchedule === null && user?.profession !== "PropertySurveyor") ||
        (thirdSchedule === null && user?.profession !== "PropertyValuator") ||
        (thirdSchedule === null && user?.profession !== "Buyer") ? (
        <>
          <Typography>
            - Schedule is not set. You will receive the scheduled dates once
            there is date confirmation from the Service Provider(s)
          </Typography>
        </>
      ) : (
        <>
          <Typography paddingBottom="15px">
            Choose your schedule availability
          </Typography>
          <span style={{display: "flex", alignItems: "center"}}>
            <TextField value={firstSchedule} sx={{width: "47.5%"}} />
            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                textAlign: "center",
                lineHeight: "36px",
                height: "40px",
                margin: "15px",
              }}
              onClick={() =>
                handleConfirmFirstDate(
                  contractProgressFirstVersionForDateSetter.id,
                  firstSchedule
                )
              }
            >
              Confirm
            </Button>
          </span>

          <span style={{display: "flex", alignItems: "center"}}>
            <TextField value={secondSchedule} sx={{width: "47.5%"}} />
            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                textAlign: "center",
                lineHeight: "36px",
                height: "40px",
                margin: "15px",
              }}
              onClick={() =>
                handleConfirmSecondDate(
                  contractProgressFirstVersionForDateSetter.id,
                  secondSchedule
                )
              }
            >
              Confirm
            </Button>
          </span>

          <span style={{display: "flex", alignItems: "center"}}>
            <TextField value={thirdSchedule} sx={{width: "47.5%"}} />
            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                textAlign: "center",
                lineHeight: "36px",
                height: "40px",
                margin: "15px",
              }}
              onClick={() =>
                handleConfirmThirdDate(
                  contractProgressFirstVersionForDateSetter.id,
                  thirdSchedule
                )
              }
            >
              Confirm
            </Button>
          </span>

          <Button
            sx={{
              background: "rgba(244, 244, 244, 1)",
              "&.MuiButtonBase-root:hover": {
                color: "rgba(1, 150, 164, 1)",
                border: "1px solid rgba(1, 150, 164, 1)",
              },
              "&:disabled": {
                color: "rgba(0, 0, 0, 0.26)",
                border: "1px solid rgba(0, 0, 0, 0.26)",
              },
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(4, 119, 130, 1)",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              textAlign: "center",
              lineHeight: "36px",
              minWidth: "100px",
              height: "40px",
              margin: "15px",
              border: "1px solid rgba(4, 119, 130, 1)",
            }}
            onClick={() =>
              handleConfirmNotAttending(
                contractProgressFirstVersionForDateSetter.id
              )
            }
          >
            Not Attending
          </Button>

          <ScheduleConfirmationPerRole confirmDateList={confirmDateList} />
        </>
      )}
    </Box>
  );
}
