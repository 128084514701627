import {useState, useEffect} from "react";
import agent from "../../../app/api/agent";
import DocumentsOverview from "./DocumentsOverview";
import DocumentViewer from "./DocumentViewer";
import {HtmlDocuments} from "../../../app/models/htmlDocument";
import DealDrawerWrapper from "../dealDrawer/DealDrawerWrapper";
import {toast} from "react-toastify";
import DrawerSpeedDial from "./DrawerSpeedDial";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import DocumentSelectModal from "../../../app/components/DocumentSelectModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useMessageCenterConnectionContext} from "../../../app/store/MessageCenterConnectionContext";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ImageUploadModal from "../../../app/components/ImageUploadModal";
import {Form} from "react-router-dom";
import React from "react";
import {TransitionProps} from "@mui/material/transitions";
import {useAppSelector} from "../../../app/store/configureStore";

interface Props {
  dealId: number;
  boxRef: React.MutableRefObject<Element | undefined>;
  prevScrollableAreaPosition: React.MutableRefObject<number | undefined>;
  isServiceCard?: boolean;
  contractProgress: any;
  property: any;
  contractProgressId: number;
  progressTracker: never[];
  openProgressDrawer: {
    openProgressDrawer: boolean;
  };
  setOpenProgressDrawer: React.Dispatch<
    React.SetStateAction<{
      openProgressDrawer: boolean;
    }>
  >;
  onHandleLvL2ClickItemClick: (id: any) => void;
  clickedLvl2ItemId: any;
  setPropertyStatus: React.Dispatch<any>;
}

const DealActionDrawer = ({
  dealId,
  boxRef,
  prevScrollableAreaPosition,
  isServiceCard = false,
  contractProgress,
  contractProgressId,
  progressTracker,
  property,
  openProgressDrawer,
  setOpenProgressDrawer,
  onHandleLvL2ClickItemClick,
  clickedLvl2ItemId,

  setPropertyStatus,
}: Props) => {
  const {user} = useAppSelector((state) => state.account);
  const [documents, setDocuments] = useState<HtmlDocuments>();
  const [images, setImages] = useState();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectDocumentWithId, setSelectDocumentWithId] = useState<number>();
  const [selectDocumentWithIdOneClick, setSelectDocumentWithIdOneClick] =
    useState<number>();
  const [documentsMVPSelectList, setDocumentsMVPSelectList] = useState<any>();
  const [documentFamilyId, setDocumentFamilyId] = useState({
    documentId: 0,
    file: "",
    documentName: "",
  });
  const [imageFamilyId, setImageFamilyId] = useState();


  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [editDocument, setEditDocument] = useState(false);
  const [refetchDocuments, setRefetchDocuments] = useState(0);
  const [signatureOpen, setSignatureOpen] = useState(false);
  const messageCenterConnectionContext = useMessageCenterConnectionContext();
  const [
    representativeRoleInContractProgress,
    setRepresentativeRoleInContractProgress,
  ] = useState();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const invocationOfTheConnection = async () => {
    if (messageCenterConnectionContext.connection) {
      await messageCenterConnectionContext.connection.invoke(
        "NotifyChangeOfTaskMessages",
        {
          NotificationText: property.status,
          DealNumber: property.property.id,
          PropertyAddress: property.property.addressLine,
          PropertyCity: property.property.city,
          PropertyState: property.property.state,
          ContractProgressId: property.contractProgressId,
        }
      );
    }
  };

  useEffect(() => {
    (user?.profession === "Representative" ||
      user?.profession === "Solicitor") &&
      contractProgressId &&
      agent.ContractProgressDeal.getRepresentativeRoleInCp(
        contractProgressId
      ).then((response) => setRepresentativeRoleInContractProgress(response));
  }, [contractProgressId, user?.profession]);

  const handleApprove = async (id: number) => {
    if (!selectDocumentWithId) {
      toast.error(<div>Open the document to Approve it</div>);
      return;
    }

    try {
      await agent.Document.approveDocument(id).then((response) => {
        if (response !== "") {
          setPropertyStatus(response);
        }
      });
      if (messageCenterConnectionContext.connection) {
        invocationOfTheConnection();
      }
      editDocumentHandler();

      toast.success("Document approved successfully");
    } catch (error) {
      toast.error(<div>Failed to Approve Document. Try again later!</div>);
    }
  };

  async function handleConfirmDelete(id: number) {
    if (id) {
      try {
        await agent.Document.deleteDocument(id)
          .then(() => setRefetchDocuments((prevCount) => prevCount + 1))
          .then(() => toast.success("Document deleted successfully"));
      } catch (error) {
        toast.error(<div>Failed to Delete Document. Try again later!</div>);
      }
    }

    setDeleteConfirmationModal(false);
  }

  const DeleteDocumentConfirmationModal = (props: any) => {
    const handleDeleteModalDocumentClose = () => {
      setDeleteConfirmationModal(false);
    };

    return (
      <div>
        <Dialog
          open={deleteConfirmationModal}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you want to delete this file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "#00838F",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#00838F",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={() => handleConfirmDelete(selectDocumentWithId!)}
            >
              Confirm
            </Button>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleDeleteModalDocumentClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleSubmitImage = async () => {
    if (!selectedImage || !imageFamilyId || !contractProgressId) return;

    const formData = new FormData();

    formData.append("FileImage", selectedImage);
    formData.append("ImageFamilyId", imageFamilyId);
    formData.append("ContractProgressId", contractProgressId.toString());

    setLoading(true);
    try {
      await agent.Document.uploadImage(formData).then(() => {
        setOpenImageModal(false);
        setRefetchDocuments((prevCount) => prevCount + 1);
        toast.success("Image uploaded successfully");
      });
    } catch (error) {
      toast.error("Error uploading image");
    }
    setLoading(false);
  };

  useEffect(() => {
    agent.Document.docTypes()
      .then((response) => {
        setDocumentsMVPSelectList(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, []);

  function handleOpenSignature() {
    if (!selectDocumentWithId) {
      toast.error(<div>Open the document to Sign it</div>);
      return;
    }
    setSignatureOpen(true);
  }

  const selectDocumentHandler = (documentId?: number) => {
    setSelectDocumentWithId(documentId);
  };

  const selectDocumentOneClickHandler = (documentId?: number) => {
    setSelectDocumentWithIdOneClick(documentId);
  };

  const editDocumentHandler = () => {
    setEditDocument((prevState) => !prevState);
  };

  async function handleSubmitData() {
    const {documentId, file, documentName} = documentFamilyId;

    try {
      // Create and upload the document
      const documentFile = {
        title: documentName,
        text: file,
        contractProgressId: contractProgressId,
        documentId: documentId,
      };

      const request = agent.Document.uploadHtmlDocumentForProperty;
      await request(dealId, documentFile)
        .then((response) => {
          if (response !== "") {
            setPropertyStatus(response);
          }
        })
        .then(
          () =>
            messageCenterConnectionContext.connection &&
            invocationOfTheConnection()
        );
      setOpenDocumentModal(false);
      setRefetchDocuments((prevCount) => prevCount + 1);
      toast.success("File uploaded successfully");

      const docTypeSelect = document.getElementById("doc-type-select");
      if (docTypeSelect) {
        (docTypeSelect as HTMLSelectElement).value = "";
      }

      const fileInput = document.getElementById("file");
      if (fileInput) {
        (fileInput as HTMLInputElement).value = "";
      }
    } catch {
      toast.error(
        <div>
          A notificiation is not sent to the Deal Participants
          <br />
          Only docx files are allowed to be uploaded. Check if the upload went
          through.
        </div>
      );
    }
  }

  const actionIcons = [
    {
      icon: (
        <DeleteOutlineIcon
          sx={{color: "#047782"}}
          onClick={() => setDeleteConfirmationModal(true)}
        />
      ),
      name: "Delete the Document",
      show: selectDocumentWithId !== undefined ? true : false,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          onClick={() => handleOpenSignature()}
        >
          <path
            d="M21.1165 11.7957C21.3971 11.1052 22.1653 10.6593 22.8993 10.7777C23.6962 10.907 24.1582 11.6612 23.8798 12.4244C23.5289 13.3678 22.9033 14.5289 21.7879 15.2122C20.8976 15.7575 18.8399 16.166 17.6465 14.2177C16.8985 12.9966 17.0016 11.9703 17.1728 10.4578C17.2703 9.58316 17.3712 7.33579 17.1299 6.13773C16.8988 4.93341 16.166 4.35744 15.7055 4.63951C15.2837 4.8979 15.1348 5.60184 15.0432 6.03498C15.0393 6.05367 15.0354 6.07186 15.0317 6.08949C14.9283 6.44915 14.3763 8.9481 14.1843 9.9671C13.9181 11.2785 12.9936 14.2032 10.6912 15.6135C7.68269 17.4564 5.31832 15.7561 4.25734 14.3687L3.00892 15.1334C2.30284 15.5659 1.4218 15.4141 1.04231 14.7946C0.662817 14.1751 0.927869 13.3213 1.63394 12.8888L2.87213 12.1303C0.658 7.5968 2.64848 4.73529 4.31646 3.71355C6.12769 2.60407 8.36572 2.94945 9.3007 4.47581C10.2357 6.00216 10.2649 9.72559 6.74881 12.6696C6.78375 12.7104 6.81883 12.7522 6.85433 12.7945C7.36922 13.4084 7.97392 14.1294 9.5343 13.1736C10.8134 12.39 11.3987 9.62375 11.8174 7.52749L11.8217 7.50551C12.2021 5.59 12.5043 4.06776 13.4252 3.12233C14.1977 2.31576 15.3042 1.84783 16.3797 1.88051C17.6117 1.91607 18.6859 2.56691 19.3899 3.71617C20.5489 5.60832 20.1205 9.45608 19.9741 10.7711C19.9524 10.9661 19.9369 11.1054 19.9337 11.1744C19.8962 11.8024 19.841 12.861 20.4627 12.7272C20.6909 12.6862 20.9138 12.315 21.1165 11.7957ZM6.77546 6.09675C6.52247 5.68374 5.93945 5.71983 5.62223 5.91415C4.90592 6.35293 4.18409 7.77055 5.35657 10.3738C7.23843 8.52962 7.10546 6.63546 6.77546 6.09675Z"
            fill="#047782"
          />
          <path
            d="M2.05265 17.5997C1.47114 17.5997 0.999733 18.137 0.999733 18.7997C0.999733 19.4625 1.47114 19.9997 2.05265 19.9997H23.9468C24.5283 19.9997 24.9997 19.4625 24.9997 18.7997C24.9997 18.137 24.5283 17.5997 23.9468 17.5997H2.05265Z"
            fill="#047782"
          />
        </svg>
      ),
      name: "Sign Document",
      show: selectDocumentWithId !== undefined ? true : false,
    },
    {
      icon: (
        <CheckIcon
          sx={{color: "#047782"}}
          onClick={() => handleApprove(selectDocumentWithId!)}
        />
      ),
      name: "Approve the Document",
      show: selectDocumentWithId !== undefined ? true : false,
    },
    {
      icon: (
        <>
          <ImageUploadModal
            setModalOpen={setOpenImageModal}
            modalState={openImageModal}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            handleSubmitImage={handleSubmitImage}
            setImageFamilyId={setImageFamilyId}
            documentsMVPSelectList={documentsMVPSelectList}
            contractProgressId={contractProgressId}
          />
          <InsertPhotoIcon
            sx={{
              color: "#047782",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
            onClick={(e: any) => {
              e.preventDefault();
              setOpenImageModal(true);
            }}
          />
        </>
      ),
      name: "Upload an Image",
      show: selectDocumentWithId !== undefined ? false : true,
    },
    {
      icon: (
        <>
          <DocumentSelectModal
            setModalOpen={setOpenDocumentModal}
            modalState={openDocumentModal}
            documentsMVPSelectList={documentsMVPSelectList}
            setDocumentFamilyId={setDocumentFamilyId}
            handleSubmitData={handleSubmitData}
            contractProgressId={contractProgressId}
          />
          <CloudUploadIcon
            sx={{
              color: "#047782",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
            onClick={(e: any) => {
              e.preventDefault();
              setOpenDocumentModal(true);
            }}
          />
        </>
      ),
      name: "Upload a Document",
      show: selectDocumentWithId !== undefined ? false : true,
    },
  ];

  useEffect(() => {
    if (!dealId || !contractProgressId || editDocument) return;

    agent.Document.getHtmlDocumentsForProperty(dealId, contractProgressId)
      .then((response) => {
        setDocuments(response);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId, editDocument, refetchDocuments, contractProgressId]);

  useEffect(() => {
    if (!dealId || !contractProgressId) return;
    agent.Document.getImages(dealId, contractProgressId).then((response) =>
      setImages(response.$values)
    );
  }, [dealId, refetchDocuments, contractProgressId]);

  return (
    <>
      <DealDrawerWrapper
        property={property}
        dealId={dealId}
        boxRef={boxRef}
        prevScrollableAreaPosition={prevScrollableAreaPosition}
        hideDocumentEditToolbar={!editDocument}
        isServiceCard={isServiceCard}
        contractProgress={contractProgress}
        openProgressDrawer={openProgressDrawer}
        setOpenProgressDrawer={setOpenProgressDrawer}
        onHandleLvL2ClickItemClick={onHandleLvL2ClickItemClick}
      >
        <DeleteDocumentConfirmationModal
          setModalOpen={setDeleteConfirmationModal}
          modalState={deleteConfirmationModal}
        />
        {selectDocumentWithId && (
          <DocumentViewer
            document={
              documents!.$values.find(
                (d: any) => d.id === selectDocumentWithId
              )!
            }
            editDocument={editDocument}
            onEditDocument={editDocumentHandler}
            onClickToGoBack={selectDocumentHandler}
            signatureOpen={signatureOpen}
            onSignatureOpen={setSignatureOpen}
            onInvocationOfTheConnection={invocationOfTheConnection}
            representativeRoleInContractProgress={
              representativeRoleInContractProgress
            }
            setPropertyStatus={setPropertyStatus}
          />
        )}

        {!loading && !selectDocumentWithId && (
          <DocumentsOverview
            property={property}
            dealId={dealId}
            contractProgressId={contractProgressId}
            documents={documents!}
            onDocumentSelect={selectDocumentHandler}
            selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
            onSelectDocumentOneClick={selectDocumentOneClickHandler}
            progressTracker={progressTracker}
            clickedLvl2ItemId={clickedLvl2ItemId}
            images={images}
            setRefetchDocuments={setRefetchDocuments}
            representativeRoleInContractProgress={
              representativeRoleInContractProgress
            }
          />
        )}
        {!loading && (
          <Box position="relative">
            <DrawerSpeedDial actionIcons={actionIcons} />
          </Box>
        )}
      </DealDrawerWrapper>
    </>
  );
};

export default DealActionDrawer;
