import {Box, Button} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./documentViewer.css";
import agent from "../../../app/api/agent";
import {toast} from "react-toastify";
import {HtmlDocument} from "../../../app/models/htmlDocument";
import {ArrowBackIosNew} from "@mui/icons-material";
import CanvasSignature from "../../../app/components/CanvasSignature";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface Props {
  document: HtmlDocument;
  editDocument: boolean;
  onEditDocument: () => void;
  onClickToGoBack: () => void;
  signatureOpen: boolean;
  onSignatureOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onInvocationOfTheConnection: () => Promise<void>;
  representativeRoleInContractProgress: undefined;
  setPropertyStatus: React.Dispatch<any>;
}

export default function DocumentViewer({
  document,
  editDocument,
  onEditDocument,
  onClickToGoBack,
  signatureOpen,
  onSignatureOpen,
  onInvocationOfTheConnection,
  representativeRoleInContractProgress,
  setPropertyStatus,
}: Props) {
  const [value, setValue] = useState<string>("");

  const contentRef = useRef<HTMLDivElement>(null);

  const handleDownloadPdf = async () => {
    if (contentRef.current) {
      const content = contentRef.current;

      if (content) {
        const canvas = await html2canvas(content);

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 306; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");

        toast.success("The document was downloaded successfully!");
      }
    }
  };

  useEffect(() => setValue(document.text), [document.text]);

  const TOOLBAR_OPTIONS = [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{font: []}],
    [{list: "ordered"}, {list: "bullet"}],
    ["bold", "italic", "underline"],
    [{color: []}, {background: []}],
    [{align: []}],
  ];

  const quillRef = useRef(null);

  useEffect(() => {
    // Ensure Quill content is loaded before printing
    if (quillRef.current) {
      setTimeout(() => {
        window.print();
      }, 1000); // Adjust delay as needed
    }
  }, []);

  const onSaveHandler = async () => {
    // let documentTitle = "";
    // const match = value.match(/<h1>(.*?)<\/h1>/);

    // if (match) {
    //   documentTitle = match[1];
    // } else {
    //   documentTitle = "No Title";
    // }

    try {
      await agent.Document.editHtmlDocument({
        id: document.id,
        // title: documentTitle,
        text: value,
        isApproved: document.isApproved,
        isSigned: document.isSigned,
        IsApprovedByBuyerSide: document.IsApprovedByBuyerSide,
        IsApprovedBySellerSide: document.IsApprovedBySellerSide,
        isApprovedByBuyer: document.isApprovedByBuyer,
        isApprovedBySeller: document.isApprovedBySeller,
        isApprovedByBuyerAttorney: document.isApprovedByBuyerAttorney,
        isApprovedByBuyerRepresentative:
          document.isApprovedByBuyerRepresentative,
        isApprovedByLender: document.isApprovedByLender,
        isApprovedBySellerAttorney: document.isApprovedBySellerAttorney,
        isApprovedBySellerRepresentative:
          document.isApprovedBySellerRepresentative,
        isSignedByBuyer: document.isSignedByBuyer,
        isSignedBySeller: document.isSignedBySeller,
        isApprovedByPropertyInsurance: document.isApprovedByPropertyInsurance,
        documentTypeNameId: document.documentTypeNameId,
        isSignedByBuyerRepresentative: document.isSignedByBuyerRepresentative,
        isSignedBySellerRepresentative: document.isSignedBySellerRepresentative,
      });
      toast.success("Your changes have been saved.");
    } catch (error) {
      console.log(error);
    }
    onEditDocument();
  };

  const onCancelHandler = () => {
    setValue(document.text);
    onEditDocument();
  };

  return (
    <Box flex={1} position="relative" height="100%" id="print-area">
      {" "}
      <Box sx={{overflowY: "scroll", height: "100%"}}>
        <Button
          variant="contained"
          sx={{
            width: "110px",
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            paddingLeft: 3,
            paddingRight: 3,
            backgroundColor: "rgba(4, 119, 130, 1)",
            zIndex: 10,
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "rgba(4, 119, 130, 1)",
            },
          }}
          onClick={editDocument ? onSaveHandler : onCancelHandler}
        >
          {editDocument ? "Save" : "Edit"}
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "110px",
            position: "absolute",
            top: "3.5rem",
            right: "0.5rem",
            paddingLeft: 3,
            paddingRight: 3,
            backgroundColor: "rgba(4, 119, 130, 1)",
            zIndex: 10,
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "rgba(4, 119, 130, 1)",
            },
          }}
          onClick={editDocument ? onCancelHandler : handleDownloadPdf}
        >
          {editDocument ? "CANCEL" : "DOWNLOAD"}
        </Button>

        {!editDocument ? (
          <Button
            variant="contained"
            sx={{
              width: "110px",
              position: "absolute",
              top: "3.5rem",
              right: "0.5rem",
              paddingLeft: 3,
              paddingRight: 3,
              backgroundColor: "rgba(4, 119, 130, 1)",
              zIndex: 10,
              "&.MuiButtonBase-root:hover": {
                backgroundColor: "rgba(4, 119, 130, 1)",
              },
            }}
            onClick={handleDownloadPdf}
          >
            DOWNLOAD
          </Button>
        ) : null}

        {editDocument && (
          <Button
            variant="outlined"
            sx={{
              height: "56px",
              width: "56px",
              minWidth: "56px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              color: "rgba(4, 119, 130, 1)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              position: "absolute",
              top: "1rem",
              left: "0.5rem",
              border: "none",
              "&.MuiButtonBase-root:hover": {
                color: "rgba(4, 119, 130, 1)",
                border: "none",
                backgroundColor: "#F2F6FA",
              },
            }}
            onClick={onCancelHandler}
          >
            <ArrowBackIosNew sx={{width: "24px", height: "24px"}} />
          </Button>
        )}
        {!editDocument && (
          <Button
            variant="outlined"
            sx={{
              height: "56px",
              width: "56px",
              minWidth: "56px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              color: "rgba(4, 119, 130, 1)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              position: "absolute",
              top: "1rem",
              left: "0.5rem",
              border: "none",
              "&.MuiButtonBase-root:hover": {
                color: "rgba(4, 119, 130, 1)",
                border: "none",
                backgroundColor: "#F2F6FA",
              },
            }}
            onClick={() => onClickToGoBack()}
          >
            <ArrowBackIosNew sx={{width: "24px", height: "24px"}} />
          </Button>
        )}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "60%",
          }}
        >
          <div ref={contentRef}>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={(value) => setValue(value)}
              modules={{toolbar: TOOLBAR_OPTIONS}}
              className={!editDocument ? "dc-none non-selectable" : ""}
              readOnly={!editDocument}
            />

            {document.mainImageSignature !== null ? (
              <img
                className={"sigImage"}
                src={document.mainImageSignature}
                alt="user generated signature"
              />
            ) : null}
            {document.sellerSignatureUrl !== null ? (
              <img
                className={"sigImagSeller"}
                src={document.sellerSignatureUrl}
                alt="seller generated signature"
              />
            ) : null}
            {document.buyerRepresentativeSignatureUrl !== null ? (
              <img
                className={"sigImagBuyerRepresentative"}
                src={document.buyerRepresentativeSignatureUrl}
                alt="buyer representative generated signature"
              />
            ) : null}
            {document.sellerRepresentativeSignatureUrl !== null ? (
              <img
                className={"sigImagSellerRepresentative"}
                src={document.sellerRepresentativeSignatureUrl}
                alt="seller representative generated signature"
              />
            ) : null}
            {signatureOpen === true ? (
              <div
                style={{
                  position: "absolute",
                  height: "250px",
                  top: "50%",
                  left: "0px",
                  marginTop: "-125px",
                  width: "100%",
                }}
              >
                <CanvasSignature
                  onSignatureOpen={onSignatureOpen}
                  document={document}
                  onInvocationOfTheConnection={onInvocationOfTheConnection}
                  representativeRoleInContractProgress={
                    representativeRoleInContractProgress
                  }
                  setPropertyStatus={setPropertyStatus}
                  onSaveHandler={onSaveHandler}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Box>
    </Box>
  );
}
