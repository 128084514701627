import {Typography, Paper, Box} from "@mui/material";
import {HtmlDocument} from "../../../app/models/htmlDocument";
// @ts-ignore
import ReactHtmlParser from "react-html-parser";
import {useAppSelector} from "../../../app/store/configureStore";

interface Props {
  document: HtmlDocument;
  onDocumentSelect: (id: number) => void;
  selectDocumentWithIdOneClick: number | undefined;
  onSelectDocumentOneClick: (documentId?: number) => void;
  representativeRoleInContractProgress: undefined;
}

export default function DocumentCard({
  document,
  onDocumentSelect,
  selectDocumentWithIdOneClick,
  onSelectDocumentOneClick,
  representativeRoleInContractProgress,
}: Props) {
  const {user} = useAppSelector((state) => state.account);

  return (
    <div
      onDoubleClick={() => onDocumentSelect(document.id)}
      onClick={() => onSelectDocumentOneClick(document.id)}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          whiteSpace: "nowrap",
        }}
      >
        {document.title}
      </Typography>
  
      <Paper
        elevation={4}
        sx={{
          width: 200,
          height: 260,
          paddingX: 2.5,
          paddingY: 3,
          fontSize: "4px",
          border: document.id === selectDocumentWithIdOneClick ? "2px solid red" : "none",
        }}
      >
        <Box
          sx={{
            wordWrap: "break-word",
            overflow: "hidden",
            height: 212,
            userSelect: "none",
          }}
        >
          {ReactHtmlParser(document.text)}
        </Box>
  
        {(() => {
          const isSigned =
            (user?.profession === "Buyer" && document.isSignedByBuyer) ||
            (user?.profession === "Seller" && document.isSignedBySeller) ||
            (representativeRoleInContractProgress === "SellerRepresentative" && document.isSignedBySellerRepresentative) ||
            (representativeRoleInContractProgress === "BuyerRepresentative" && document.isSignedByBuyerRepresentative);
  
          const isApproved =
            (user?.profession === "Buyer" && document.isApprovedByBuyer) ||
            (user?.profession === "Seller" && document.isApprovedBySeller) ||
            (representativeRoleInContractProgress === "BuyerRepresentative" && document.isApprovedByBuyerRepresentative) ||
            (representativeRoleInContractProgress === "SellerRepresentative" && document.isApprovedBySellerRepresentative) ||
            (representativeRoleInContractProgress === "BuyerAttorney" && document.isApprovedByBuyerAttorney) ||
            (representativeRoleInContractProgress === "SellerAttorney" && document.isApprovedBySellerAttorney) ||
            (user?.profession === "Lender" && document.isApprovedByLender) ||
            (user?.profession === "BuildingInsurer" && document.isApprovedByPropertyInsurance);
  
          if (isSigned) {
            return (
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  color: "green",
                  border: "1px solid black",
                  transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                  position: "relative",
                  top: "-125px",
                }}
              >
                APPROVED <br />
                and SIGNED
              </div>
            );
          }
  
          if (isApproved) {
            return (
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "green",
                  border: "1px solid black",
                  transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                  position: "relative",
                  top: "-95px",
                }}
              >
                APPROVED
              </div>
            );
          }
          return null;
        })()}
      </Paper>
    </div>
  );
}
