import {Box, Chip, Divider, Grid, Typography} from "@mui/material";
import DocumentCard from "./DocumentCard";
import {HtmlDocuments} from "../../../app/models/htmlDocument";
import OfferFormCard from "./OfferFormCard";
import {useAppSelector} from "../../../app/store/configureStore";
import ConfirmationFormSeller from "../../../app/components/ConfirmationFormSeller";
import {
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {useEffect, useRef, useState} from "react";
import agent from "../../../app/api/agent";
import {toast} from "react-toastify";
import ConfirmationFormBuyer from "../../../app/components/ConfirmationFormBuyer";

interface Props {
  documents: HtmlDocuments;
  onDocumentSelect: (id: number) => void;
  selectDocumentWithIdOneClick: number | undefined;
  onSelectDocumentOneClick: (documentId?: number) => void;
  dealId: number;
  progressTracker: never[];
  clickedLvl2ItemId: any;
  images: any;
  setRefetchDocuments: React.Dispatch<React.SetStateAction<number>>;
  contractProgressId: number;
  property: any;
  representativeRoleInContractProgress: undefined;
}

interface Image {
  id: number;
  imageFamilyId: number;
  fileName: string;
  imageUrl: string;
}

export default function DocumentsOverview({
  documents,
  onDocumentSelect,
  progressTracker,
  selectDocumentWithIdOneClick,
  onSelectDocumentOneClick,
  dealId,
  clickedLvl2ItemId,
  images,
  setRefetchDocuments,
  contractProgressId,
  representativeRoleInContractProgress,
  property,
}: Props) {
  const {user} = useAppSelector((state) => state.account);
  const [open, setOpen] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const targetDivM1 = useRef<HTMLDivElement>(null);
  const targetDivM2 = useRef<HTMLDivElement>(null);
  const targetDivM3 = useRef<HTMLDivElement>(null);
  const targetDivM4 = useRef<HTMLDivElement>(null);
  const targetDivM5 = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);

  const scrollToTarget = (containerRef: any, targetRef: any) => {
    if (containerRef.current && targetRef.current) {
      containerRef.current.scrollTo({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleGridClick = () => {
    if (selectDocumentWithIdOneClick !== undefined) {
      onSelectDocumentOneClick(undefined);
    }
  };

  const handleOpenModalImage = (image: any) => {
    setSelectedImage(image);
    setOpenModalImage(true);
  };

  const deleteImage = (id: any) => {
    agent.Document.deleteImage(id).then(() => {
      setRefetchDocuments((prevCount) => prevCount + 1);
      toast.success("Image deleted successfully");
      setOpenModalImage(false);
    });
  };

  const ImageModal = (props: any) => {
    const handleClose = () => {
      setOpenModalImage(false);
      setSelectedImage(null);
    };

    return (
      <div>
        <Dialog
          open={openModalImage}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {selectedImage && (
              <Box>
                <img
                  src={selectedImage.imageUrl}
                  alt={selectedImage.fileName}
                  style={{width: "100%", height: "auto"}}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={() => deleteImage(selectedImage?.id)}
            >
              Delete image
            </Button>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  useEffect(() => {
    if (clickedLvl2ItemId >= 1 && clickedLvl2ItemId <= 12) {
      scrollToTarget(containerRef, targetDivM1);
    } else if (clickedLvl2ItemId >= 13 && clickedLvl2ItemId <= 18) {
      scrollToTarget(containerRef, targetDivM2);
    } else if (clickedLvl2ItemId >= 19 && clickedLvl2ItemId <= 21) {
      scrollToTarget(containerRef, targetDivM3);
    } else if (clickedLvl2ItemId >= 22 && clickedLvl2ItemId <= 24) {
      scrollToTarget(containerRef, targetDivM4);
    } else if (clickedLvl2ItemId >= 25 && clickedLvl2ItemId <= 31) {
      scrollToTarget(containerRef, targetDivM5);
    }
  }, [clickedLvl2ItemId]);

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleConfirm = () => {
    agent.ContractProgressDeal.FinalConfirmationUpdate(contractProgressId).then(
      () => {
        toast.success("Confirmation is successful");
        setOpen(false);
      }
    );
  };

  const ChildModalForSellerConfirmation = (props: any) => {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Do you confirm Receipt of full principal payment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={handleConfirm}
            >
              YES
            </Button>

            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const ChildModalForBuyerConfirmation = (props: any) => {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Do you confirm receipt of Property Accesss?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              onClick={handleConfirm}
            >
              YES
            </Button>

            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={6}
        flex={1}
        paddingX={10}
        ref={containerRef}
        sx={{
          position: "relative",
          overflowY: "auto",
          marginTop: "-25px",
          paddingTop: "10px",
          paddingBottom: "32px",
          height: "100%",
        }}
        onClick={handleGridClick}
      >
        {representativeRoleInContractProgress === "BuyerRepresentative" ? (
          <Grid item>
            <OfferFormCard
              onDocumentSelect={onDocumentSelect}
              selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
              onSelectDocumentOneClick={onSelectDocumentOneClick}
              dealId={dealId}
              progressTracker={progressTracker}
              contractProgressId={contractProgressId}
            />
          </Grid>
        ) : null}
        {user?.profession === "Seller" ? (
          <Grid item>
            <ChildModalForSellerConfirmation
              setModalOpen={setOpen}
              modalState={open}
            />
            <ConfirmationFormSeller
              progressTracker={progressTracker}
              onSetOpen={setOpen}
            />
          </Grid>
        ) : null}
        {user?.profession === "Buyer" ? (
          <Grid item>
            <ChildModalForBuyerConfirmation
              setModalOpen={setOpen}
              modalState={open}
            />
            <ConfirmationFormBuyer
              progressTracker={progressTracker}
              onSetOpen={setOpen}
            />
          </Grid>
        ) : null}

        <div ref={targetDivM1}></div>
        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Contract - P&S Agreement and Contract Amendment(s)"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 1 ||
                document.documentTypeNameId === 12) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 1 || image.imageFamilyId === 12) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Contract - Escrow Payment Receipt"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 2 ||
                document.documentTypeNameId === 16) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 2 || image.imageFamilyId === 16) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Contract - Mortgage Pre-Approval" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 3 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 3 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Mortgage - Buyer Document(s)" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 4 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 4 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Mortgage - Lender Document(s) for Buyer Signature"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 5 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 5 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Internal Documents - Buyer Side" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 6 ||
                document.documentTypeNameId === 13 ||
                document.documentTypeNameId === 22 ||
                document.documentTypeNameId === 28 ||
                document.documentTypeNameId === 38) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 6 ||
                image.imageFamilyId === 13 ||
                image.imageFamilyId === 22 ||
                image.imageFamilyId === 28 ||
                image.imageFamilyId === 38) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Internal Documents - Seller Side" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 7 ||
                document.documentTypeNameId === 14 ||
                document.documentTypeNameId === 23 ||
                document.documentTypeNameId === 29 ||
                document.documentTypeNameId === 39) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 7 ||
                image.imageFamilyId === 14 ||
                image.imageFamilyId === 23 ||
                image.imageFamilyId === 29 ||
                image.imageFamilyId === 39) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Due Diligence - Exchange Document(s) between Buyer Side & Seller Side"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 8 ||
                document.documentTypeNameId === 15 ||
                document.documentTypeNameId === 24 ||
                document.documentTypeNameId === 30 ||
                document.documentTypeNameId === 40) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}
        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 8 ||
                image.imageFamilyId === 15 ||
                image.imageFamilyId === 24 ||
                image.imageFamilyId === 30 ||
                image.imageFamilyId === 40) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <div ref={targetDivM2}></div>
        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Due Diligence - Report Document(s)" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 9 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 9 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Due Diligence - Invoices/Receipts" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 10 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 10 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Due Diligence - Work Request and Agreement"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 11 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}
        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 11 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <div ref={targetDivM3}></div>
        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Mortgage - Buyer Document(s)" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 18 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 18 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Insurance - Insurance Policies" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 17 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 17 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Mortgage - Lender Document(s) for Buyer Signature"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 19 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 19 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Due Diligence - Lender Appraisal" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 20 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 20 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Mortgage - Final Completion Documents"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              (document.documentTypeNameId === 21 ||
                document.documentTypeNameId === 31) && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}
        {images &&
          images.map(
            (image: any) =>
              (image.imageFamilyId === 21 || image.imageFamilyId === 31) && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <div ref={targetDivM4}></div>
        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Ownership - Title Report(s) and Exchange Documents"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 25 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 25 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Ownership - Title Deed Document(s) for Seller Signature"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 26 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 26 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Ownership - Final Deed and Insurance Document(s)"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 27 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 27 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <div ref={targetDivM5}></div>
        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip sx={{}} label="Completion - Seller Document(s)" size="small" />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 33 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 33 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Completion - Documents for Buyer/Seller Signature"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 34 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 34 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Completion - Final Completion Statement Document(s)"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 35 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 35 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Completion - Receipts for Final Payments"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 36 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 36 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}

        <Divider sx={{width: "100%", paddingTop: "20px"}}>
          <Chip
            sx={{}}
            label="Completion - Executed Transaction Document(s)"
            size="small"
          />
        </Divider>

        {documents &&
          documents.$values.map(
            (document) =>
              document.documentTypeNameId === 37 && (
                <Grid item key={document.id + document.text}>
                  <DocumentCard
                    document={document}
                    onDocumentSelect={onDocumentSelect}
                    selectDocumentWithIdOneClick={selectDocumentWithIdOneClick}
                    onSelectDocumentOneClick={onSelectDocumentOneClick}
                    representativeRoleInContractProgress={
                      representativeRoleInContractProgress
                    }
                  />
                </Grid>
              )
          )}

        {images &&
          images.map(
            (image: any) =>
              image.imageFamilyId === 37 && (
                <>
                  <ImageModal
                    setOpen={setOpenModalImage}
                    open={openModalImage}
                  />
                  <Grid item key={image.id}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {image.fileName}
                    </Typography>
                    <img
                      src={image.imageUrl}
                      alt=""
                      style={{
                        height: 260,
                        width: 200,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        objectFit: "cover", 
                        paddingLeft: 3,
                        paddingRight: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenModalImage(image)}
                    />
                  </Grid>
                </>
              )
          )}
      </Grid>
    </>
  );
}
