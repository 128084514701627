import {useEffect, useState} from "react";
import {Form, NavLink, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import agent from "../../app/api/agent";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {useAppSelector} from "../../app/store/configureStore";

const EditProperty = () => {
  const navigate = useNavigate();
  const {user} = useAppSelector((state: any) => state.account);
  const {id} = useParams<{id: string}>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [property, setProperty] = useState({
    id: 0,
    country: "",
    state: "",
    city: "",
    postalCode: "",
    addressLine: "",
    listPrice: 0,
    tenure: "",
    summary: "",
    type: "",
    lengthOfLease: 0,
    parking: "",
    durationOfListing: "",
    built: 0,
    siteSize: 0,
    groundRent: "",
    buildingSize: 0,
    serviceCharge: 0,
    exterior: "",
    slab: "",
    buildingDimensions: "",
    columnSpacing: "",
    ceilingHeight: "",
    dockHighDoors: "",
    driveInDoors: "",
    elevator: "",
    sprinkler: "",
    waterAndSewer: "",
    gas: "",
    power: "",
    powerAvailable: "",
    userId: "",
    mainImageUrl: null,
    images: [],
  });

  const countries = [
    {
      value: "United Kingdom",
    },
  ];

  const power = [
    {
      value: "Yes",
    },
    {
      value: "No",
    },
  ];

  const typeItems = [
    {
      value: "Data Center",
    },
    {
      value: "Home",
    },
    {
      value: "Flex",
    },
    {
      value: "Hospitality",
    },
    {
      value: "Land",
    },
    {
      value: "Life Sciences",
    },
    {
      value: "Medical",
    },
    {
      value: "Multifamily",
    },
    {
      value: "Office",
    },
    {
      value: "Retail",
    },
    {
      value: "Speciality",
    },
  ];

  const tenureItems = [
    {
      value: "Freehold",
    },
    {
      value: "Leasehold",
    },
  ]

  const usStates = ["England", "Wales"];

  const [galleryImages, setGalleryImages] = useState([]);
  useEffect(() => {
    if (id) {
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);

          const galleryValues = response.gallery.$values;
          const galleryUrls = galleryValues.map((img: any) => img.imageUrl);
          setGalleryImages(galleryUrls);
          // const listedDate = new Date("2023-06-09T22:00:00Z");
          // const formattedDate = listedDate.toISOString().substring(0, 10);
          // setListedProperty(formattedDate);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  async function cancelEdit() {
    navigate(`/property/det/${id}`);
  }

  const handleInputChange = (event: any) => {
    let {name, value} = event.target;
    setProperty({...property, [name]: value});
  };

  const [file, setFile] = useState(null);
  const handleMainImageChange = (event: any) => {
    const file1 = event.target.files[0];
    setProperty({...property, mainImageUrl: file1});
    setFile(file1);
  };

  const [files, setFiles] = useState<File[]>([]);

  const handleGalleryImageChange = (event: any) => {
    const files1 = event.target.files;
    if (files1) {
      const filesArray = Array.from(files1 as FileList);
      setFiles(filesArray);
      setProperty({...property, images: files1});
    }
  };

  const handleSubmit = async (event: any, id: number) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    console.log(property);
    formData.append("id", property.id.toString());
    formData.append("country", property.country);
    formData.append("state", property.state);
    formData.append("city", property.city);
    formData.append("postalCode", property.postalCode);
    formData.append("addressLine", property.addressLine);
    formData.append("listPrice", property.listPrice.toString());
    formData.append("tenure", property.tenure);
    formData.append("summary", property.summary);
    formData.append("type", property.type);
    formData.append("lengthOfLease", property.lengthOfLease.toString());
    formData.append("parking", property.parking);
    formData.append("durationOfListing", property.durationOfListing);
    formData.append("built", property.built.toString());
    formData.append("siteSize", property.siteSize.toString());
    formData.append("groundRent", property.groundRent);
    formData.append("buildingSize", property.buildingSize.toString());
    formData.append("serviceCharge", property.serviceCharge.toString());
    formData.append("exterior", property.exterior);
    formData.append("slab", property.slab);
    formData.append("buildingDimensions", property.buildingDimensions);
    formData.append("columnSpacing", property.columnSpacing);
    formData.append("ceilingHeight", property.ceilingHeight);
    formData.append("dockHighDoors", property.dockHighDoors);
    formData.append("driveInDoors", property.driveInDoors);
    formData.append("elevator", property.elevator);
    formData.append("sprinkler", property.sprinkler);
    formData.append("waterAndSewer", property.waterAndSewer);
    formData.append("gas", property.gas);
    formData.append("power", property.power);
    formData.append("powerAvailable", property.powerAvailable);

    if (property.mainImageUrl !== null) {
      console.log(property.mainImageUrl);
      formData.append("mainImageUrl", property.mainImageUrl);
    }

    if (property.mainImageUrl !== null) {
      if (typeof property.mainImageUrl === "string") {
        // User didn't change the image, so we use the old one
        formData.append("oldMainImageUrl", property.mainImageUrl);
      } else {
        // User changed the image, so we upload the new one
        formData.append("mainImageUrl", property.mainImageUrl);
      }
    }

    if (
      property.images === null ||
      property.images === undefined ||
      property.images.length === 0
    ) {
    } else {
      console.log(galleryImages);
      console.log(property.images);
      for (let i = 0; i < property.images.length; i++) {
        formData.append("images", property.images[i]);
      }
      //old images --- for now it will be updated in the feature
      for (let i = 0; i < galleryImages.length; i++) {
        formData.append("gallery", galleryImages[i]);
      }
    }
    console.log(formData);
    await agent.Marketplace.update(id, formData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.success("Property was edited Successfully");
        navigate("/marketplace");
      })
      .catch((error) => console.log(error));
  };

  function removeProperty(id: number) {
    if (!id) {
      return;
    }
    agent.Marketplace.delete(id)
      .then(() => {
        toast.success(`Property #${id} was successfully removed`);
        navigate("/marketplace");
      })
      .catch((error) => console.log(error));
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildModal = (props: any) => {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you want to save these changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Form onClick={(e) => handleSubmit(e, property.id)}>
              <Button
                sx={{
                  background: "#047782",
                  "&:hover": {
                    background: "#0196A4",
                  },
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  color: "#fff",
                  borderRadius: "4px",
                  letterSpacing: "1.25px",
                  margin: "15px",
                }}
                onClick={handleClose}
              >
                SAVE
              </Button>
            </Form>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
            justifyContent: "space-between",
          }}
        >
          <NavLink to={`/property/det/${id}`}>
            <ArrowBackIosNewIcon
              sx={{
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </NavLink>
          {user.id === property.userId && (
            <Button
              sx={{
                margin: "10px",
                background: "rgba(4, 119, 130, 1)",
                "&:hover": {
                  backgroundColor: "rgba(208, 235, 238, 1)",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                letterSpacing: "1.25px",
              }}
              onClick={() => removeProperty(property.id)}
            >
              Remove Property
            </Button>
          )}
        </Box>

        {property && (
          <>
            <Card
              sx={{
                padding: "20px",
              }}
            >
              <Form
                onSubmit={(e) => handleSubmit(e, property.id)}
                style={{padding: "20px"}}
              >
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                    width: "50%",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Roboto",
                        color: "#424242",
                        fontWeight: "600",
                        fontSize: "23px",
                      }}
                    >
                      Edit a Property
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      disabled
                      value={property.id}
                      label="Property#"
                      type="number"
                      name="propertyNumber"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.addressLine}
                      label="Address"
                      type="text"
                      name="addressLine"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.country}
                      select
                      label="Country"
                      type="text"
                      name="country"
                      className="form-control"
                      sx={{width: "100%"}}
                    >
                      {countries.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.state}
                      select
                      label="State"
                      type="text"
                      name="state"
                      sx={{width: "100%"}}
                    >
                      {usStates.map((option: any) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.city}
                      type="text"
                      label="City"
                      name="city"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.postalCode}
                      label="Postal Code"
                      type="text"
                      name="postalCode"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      className="no-spinners"
                      value={property.listPrice}
                      label="List Price"
                      type="number"
                      name="listPrice"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.durationOfListing}
                      type="text"
                      label="Duration of Listing"
                      name="durationOfListing"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.type}
                      select
                      label="Type"
                      type="text"
                      name="type"
                      sx={{width: "100%"}}
                      required
                    >
                      {typeItems.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                  <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.tenure}
                      select
                      label="Tenure"
                      type="text"
                      name="tenure"
                      sx={{width: "100%"}}
                      required
                    >
                      {tenureItems.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>     
              
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.siteSize}
                      type="text"
                      label="SiteSize"
                      name="siteSize"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.groundRent}
                      label="Lease Rate Type"
                      type="text"
                      name="groundRent"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      className="no-spinners"
                      value={property.buildingSize}
                      type="number"
                      label="Building Size"
                      name="buildingSize"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      className="no-spinners"
                      value={property.serviceCharge}
                      type="number"
                      label="Service Charge"
                      name="serviceCharge"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.built}
                      label="Built"
                      type="number"
                      name="built"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      className="no-spinners"
                      value={property.lengthOfLease}
                      label="Length Of Lease"
                      type="number"
                      name="lengthOfLease"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      label="Summary"
                      value={property.summary}
                      type="text"
                      name="summary"
                      sx={{width: "100%"}}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                    width: "50%",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Roboto",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Features
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.exterior}
                      label="Exterior"
                      type="text"
                      name="exterior"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.slab}
                      label="Slab"
                      type="text"
                      name="slab"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.buildingDimensions}
                      label="Building Dimensions"
                      type="text"
                      name="buildingDimensions"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.columnSpacing}
                      label="Column Spacing"
                      type="text"
                      name="columnSpacing"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.ceilingHeight}
                      type="text"
                      label="Ceiling Height"
                      name="ceilingHeight"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.dockHighDoors}
                      label="Dock-High Doors"
                      type="text"
                      name="dockHighDoors"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.driveInDoors}
                      label="Drive In Doors"
                      type="text"
                      name="driveInDoors"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.elevator}
                      type="text"
                      label="Elevator"
                      name="elevator"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.sprinkler}
                      label="Sprinkler"
                      type="text"
                      name="sprinkler"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.parking}
                      label="Parking"
                      type="text"
                      name="parking"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Roboto",
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "700",
                      width: "100%",
                      paddingLeft: "20px",
                      paddingTop: "55px",
                    }}
                  >
                    Utilities
                  </Typography>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.waterAndSewer}
                      label="Water & Sewer"
                      type="text"
                      name="waterAndSewer"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.gas}
                      type="text"
                      label="Gas"
                      name="gas"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.power}
                      label="Power"
                      type="text"
                      name="power"
                      sx={{width: "100%"}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      onChange={handleInputChange}
                      value={property.powerAvailable}
                      select
                      label="Power Available"
                      type="text"
                      name="powerAvailable"
                      sx={{width: "100%"}}
                    >
                      {power.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <Typography>Main Image</Typography>
                      {file ? (
                        <img
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            marginRight: "15px",
                          }}
                          src={URL.createObjectURL(file)}
                          alt="file"
                        />
                      ) : (
                        <img
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            marginRight: "15px",
                          }}
                          src={property.mainImageUrl!}
                          alt={property.mainImageUrl!}
                        />
                      )}
                    </Box>

                    <Box>
                      <Typography>Change Main Image</Typography>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/*"
                        onChange={handleMainImageChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <Typography>Gallery</Typography>
                      {Array.isArray(files) && files.length > 0
                        ? files.map((file) => (
                            <>
                              <img
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  marginRight: "15px",
                                }}
                                src={URL.createObjectURL(file)}
                                alt={`${file.name}`}
                                key={`${file.name}`}
                              />
                            </>
                          ))
                        : galleryImages &&
                          galleryImages.map((url, index) => (
                            <>
                              <img
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  marginRight: "15px",
                                }}
                                key={`${index}`}
                                src={url}
                                alt={`${index}`}
                              />
                            </>
                          ))}
                    </Box>

                    <Box>
                      <label htmlFor="mainImageUrl">Change Gallery</label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleGalleryImageChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <ChildModal setModalOpen={setOpen} modalState={open} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
            sx={{
              background: "#047782",
              "&:hover": {
                background: "#0196A4",
              },
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#fff",
              borderRadius: "4px",
              letterSpacing: "1.25px",
              margin: "15px",
            }}
          >
            Submit
          </Button>

          <Button
            onClick={cancelEdit}
            sx={{
              background: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontSize: "0.875rem",
              fontWeight: "400",
              color: "#047782",
              letterSpacing: "1.25px",
              border: "1px solid #047782",
              borderRadius: "4px",
              margin: "15px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditProperty;
