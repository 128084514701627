import {
  Grid,
  Typography,
  Card,
  Box,
  CardActions,
  Button,
  FormControl,
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppSelector} from "../../app/store/configureStore";
import {FieldValues, useForm} from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInputCustom";
import AppSelectListStates from "../../app/components/AppSelectListCustomStates";
import AppSelectList from "../../app/components/AppSelectListCustom";
import agent from "../../app/api/agent";
import {LoadingButton} from "@mui/lab";
import PropertyQRCode from "../../app/components/PropertyQRCode";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import ReusableDropzoneSingleImage from "../../app/components/ReusableDropzoneSingleImage";
import ReusableDropzoneMultipleImages from "../../app/components/ReusableDropzoneMultipleImages";

const NewProperty = () => {
  const {user} = useAppSelector((state: any) => state.account);
  const navigate = useNavigate();
  const countries = ["United Kingdom"];
  const power = ["Yes", "No"];
  const usStates = ["England", "Wales"];

  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [link, setLink] = useState("");

  const typeItems = [
    "Data Center",
    "Home",
    "Flex",
    "Hospitality",
    "Land",
    "Life Sciences",
    "Medical",
    "Multifamily",
    "Office",
    "Retail",
    "Speciality",
  ];

  const tenureItems = [
    "Freehold",
    "Leasehold"
  ]

  if (!user) {
    navigate("/login");
  }

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    try {
      console.log(data);
      await agent.Marketplace.create(data)
        .then((response) => {
          setOpenModal(true);

          const test = handleQRLink(`/property/nouser/det/${response.id}`);

          setLink(test);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  function handleQRLink(url: any) {
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = domain.concat(url);

    console.log(fullAddress);

    return fullAddress;
  }

  async function cancelEdit() {
    navigate("/marketplace");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <ArrowBackIosNewIcon
            onClick={() => {
              navigate("/marketplace");
            }}
            sx={{
              display: "flex",
              color: "rgba(112, 112, 112, 1)",
              "&:hover": {
                color: "rgba(208, 235, 238, 1)",
              },
            }}
          />
        </Box>

        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Card sx={{padding: "20px"}}>
            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Roboto",
                    color: "#424242",
                    fontWeight: "600",
                    fontSize: "23px",
                    paddingTop: "30px",
                  }}
                >
                  Add a Property
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  disabled
                  label="Property#"
                  type="number"
                  name="PropertyNumber"
                  control={control}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  label="Address"
                  type="text"
                  name="AddressLine"
                  control={control}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectList
                  control={control}
                  items={countries}
                  label="Country"
                  name="Country"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectListStates
                  control={control}
                  items={usStates}
                  label="State"
                  name="State"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="City"
                  name="City"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Postal Code"
                  type="text"
                  name="PostalCode"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="List Price"
                  type="number"
                  name="ListPrice"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="Duration of Listing"
                  name="DurationOfListing"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                {/* <AppTextInput
                  control={control}
                  label="Type"
                  type="text"
                  name="Type"
                /> */}

                <AppSelectList
                  control={control}
                  items={typeItems}
                  label="Type"
                  name="Type"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectList
                  control={control}
                  items={tenureItems}
                  label="Tenure"
                  name="Tenure"
                  required
                />   
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="Site Size"
                  name="SiteSize"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Ground Rent"
                  type="text"
                  name="GroundRent"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="number"
                  label="Building Size"
                  name="BuildingSize"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="number"
                  label="Service Charge"
                  name="ServiceCharge"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Built"
                  type="text"
                  name="Built"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Length Of Lease"
                  type="number"
                  name="LengthOfLease"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Summary"
                  type="text"
                  name="Summary"
                  multiline={true}
                  rows={6}
                  required
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Roboto",
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Features
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Exterior"
                  type="text"
                  name="Exterior"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Slab"
                  type="text"
                  name="Slab"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Building Dimensions"
                  type="text"
                  name="BuildingDimensions"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Column Spacing"
                  type="text"
                  name="ColumnSpacing"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="Ceiling Height"
                  name="CeilingHeight"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Dock-High Doors"
                  type="text"
                  name="DockHighDoors"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Drive In Doors"
                  type="text"
                  name="DriveInDoors"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="Elevator"
                  name="Elevator"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Sprinkler"
                  type="text"
                  name="Sprinkler"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Parking"
                  type="text"
                  name="Parking"
                />
              </Grid>

              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "700",
                  width: "100%",
                  paddingLeft: "20px",
                  paddingTop: "55px",
                }}
              >
                Utilities
              </Typography>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Water & Sewer"
                  type="text"
                  name="WaterAndSewer"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  type="text"
                  label="Gas"
                  name="Gas"
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Power"
                  type="text"
                  name="Power"
                />
              </Grid>

              <Grid item xs={6}>
                <AppSelectList
                  control={control}
                  items={power}
                  label="Power Available"
                  name="PowerAvailable"
                />
              </Grid>

              <Grid item xs={6}>
                <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
                  <Typography sx={{paddingBottom: "10px"}}>
                    Main Image
                  </Typography>
                  <CardActions>
                    <FormControl>
                      <ReusableDropzoneSingleImage
                        control={control}
                        name="FileImage"
                      >
                        <Box
                          display="inline-flex"
                          width="100%"
                          alignItems="center"
                        >
                          <img
                            src={"/images/DropzoneIcon.png"}
                            alt="IMG"
                            style={{
                              height: "46px",
                              width: "46px",
                              marginRight: "15px",
                            }}
                          />
                          <Typography variant="subtitle1">
                            Drag and drop a photo
                            <Typography
                              sx={{
                                textDecoration: "underline",
                                display: "inline-flex",
                              }}
                            >
                              or click here to browse
                            </Typography>
                          </Typography>
                        </Box>
                      </ReusableDropzoneSingleImage>
                    </FormControl>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={6} paddingBottom="60px">
                <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
                  <Typography paddingBottom="10px">Gallery</Typography>
                  <CardActions>
                    <FormControl>
                      <ReusableDropzoneMultipleImages
                        name="Images"
                        control={control}
                      >
                        <Box
                          display="inline-flex"
                          width="100%"
                          alignItems="center"
                        >
                          <img
                            src={"/images/DropzoneIcon.png"}
                            alt="IMG"
                            style={{
                              height: "46px",
                              width: "46px",
                              marginRight: "15px",
                            }}
                          />
                          <Typography variant="subtitle1">
                            Drag and drop a photo
                            <Typography
                              sx={{
                                textDecoration: "underline",
                                display: "inline-flex",
                              }}
                            >
                              or click here to browse
                            </Typography>
                          </Typography>
                        </Box>
                      </ReusableDropzoneMultipleImages>
                    </FormControl>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <LoadingButton
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
      <PropertyQRCode open={openModal} link={link} />
    </>
  );
};

export default NewProperty;
