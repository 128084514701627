import {useEffect, useReducer} from "react";
import agent from "../../../app/api/agent";
import {useAppSelector} from "../../../app/store/configureStore";
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import DealList from "./DealList";
import ServiceList from "./ServiceList";
import SearchBarOpen from "../../../app/components/SearchBarOpen";
import SearchBarClosed from "../../../app/components/SearchBarClosed";
import DealsFilter from "../../../app/components/DealsFilter";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";
import {clearOpenDealCardAfterCloseOfDealCard} from "../../../app/store/uiSlice";
import {useDispatch} from "react-redux";

const DealPage = () => {
  const initialState = {
    anchorElCategory: null,
    openCategory: false,
    anchorElPrice: null,
    openPrice: false,
    anchorElDealNumber: null,
    openDealNumber: false,
    anchorElPropertyId: null,
    openPropertyId: false,
    isActive: false,
    isFilterActive: false,
    onlyRfps: false,
    primaryView: true,
    loading: false,
    allProperties: [],
    allRfps: [],
    adjustedProperties: [],
    adjustedRfps: [],
    progressPartners: [],
  };

  function reducer(state: any, action: any): any {
    switch (action.type) {
      case "setDeals": {
        return {
          ...state,
          allProperties: action.payload.propertyVM.$values,
          adjustedProperties: action.payload.propertyVM.$values,
          allRfps: action.payload.rfp.$values,
          adjustedRfps: action.payload.rfp.$values,
        };
      }
      case "toggleCategory":
        return {
          ...state,
          anchorElCategory: action.payload ? action.payload : null,
          openCategory: !state.openCategory,
        };
      case "togglePrice":
        return {
          ...state,
          anchorElPrice: action.payload ? action.payload : null,
          openPrice: !state.openPrice,
        };
      case "togglePropertyId":
        return {
          ...state,
          anchorElPropertyId: action.payload ? action.payload : null,
          openPropertyId: !state.openPropertyId,
        };
      case "toggleDealNumber":
        return {
          ...state,
          anchorElDealNumber: action.payload ? action.payload : null,
          openDealNumber: !state.openDealNumber,
        };

      case "highToLow":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "lowToHigh":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "ascendingDealId":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "descendingDealId":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "ascendingPropertyId":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "descendingPropertyId":
        return {
          ...state,
          adjustedProperties: action.payload,
        };
      case "setIsActive":
        return {
          ...state,
          isActive: !state.isActive,
          lowercasedvalue: state.isActive ? state.lowercasedvalue : "",
        };
      case "setIsFilterActive":
        return {
          ...state,
          isFilterActive: !state.isFilterActive,
        };

      case "allDeals":
        return {
          ...state,
          primaryView: false,
          onlyRfps: false,
        };

      case "showOnlyProperties":
        return {
          ...state,
          primaryView: true,
          onlyRfps: false,
        };

      case "showOnlyRfps":
        return {
          ...state,
          primaryView: false,
          onlyRfps: true,
        };
      case "searchResults":
        return {
          ...state,
          adjustedProperties: action.payload.properties,
          adjustedRfps: action.payload.rfps,
          primaryView: false,
          onlyRfps: false,
        };
      case "setLoading":
        return {
          ...state,
          loading: action.payload,
        };

      default:
        throw new Error("Unknown action");
    }
  }

  const [
    {
      anchorElPrice,
      openPrice,
      anchorElDealNumber,
      openDealNumber,
      anchorElCategory,
      openCategory,
      anchorElPropertyId,
      openPropertyId,
      isFilterActive,
      isActive,
      primaryView,
      onlyRfps,
      allProperties,
      allRfps,
      adjustedProperties,
      adjustedRfps,
      loading,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const globalDispatch = useDispatch();
  let lowercasedvalue = "";

  if (!user) {
    navigate("/login");
  }

  if (user && !user?.isConfirmed) {
    navigate("/create-profile");
  }

  const handleDealSearch = (value: any) => {
    lowercasedvalue = value.toLowerCase().trim();

    const filteredProperties = allProperties.filter(
      (p: any) =>
        p.property.city.toLowerCase().includes(lowercasedvalue) ||
        p.property.state.toLowerCase().includes(lowercasedvalue) ||
        p.property.postalCode.toLowerCase().includes(lowercasedvalue) ||
        p.property.addressLine.toLowerCase().includes(lowercasedvalue) ||
        p.property.id.toString().includes(lowercasedvalue)
    );

    const filteredRfps = allRfps.filter(
      (r: any) =>
        r.addressOfProperty.toLowerCase().includes(lowercasedvalue) ||
        r.serviceProvider.toLowerCase().includes(lowercasedvalue) ||
        r.nameOfSeeker.toLowerCase().includes(lowercasedvalue) ||
        r.addressOfSeeker.toLowerCase().includes(lowercasedvalue) ||
        r.id.toString().includes(lowercasedvalue)
    );

    dispatch({
      type: "searchResults",
      payload: {
        properties: filteredProperties,
        rfps: filteredRfps,
      },
    });
  };

  const handleToggleSearch = () => {
    dispatch({type: "setIsActive"});
    if (isActive) {
      handleDealSearch("");
    }
  };

  const handleToggleFillter = () => {
    dispatch({type: "setIsFilterActive"});
    globalDispatch(clearOpenDealCardAfterCloseOfDealCard());
  };

  const handleToggleCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "toggleCategory", payload: event.currentTarget});
  };

  const handleTogglePrice = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({type: "togglePrice", payload: event.currentTarget});
  };

  const handleTogglePropertyId = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    dispatch({type: "togglePropertyId", payload: event.currentTarget});
  };

  const handleHighToLow = (value: any) => {
    dispatch({
      type: "highToLow",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) =>
            Number(a.property.listPrice) - Number(b.property.listPrice)
        ),
    });
  };

  const handleLowToHigh = (value: any) => {
    dispatch({
      type: "lowToHigh",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) =>
            Number(b.property.listPrice) - Number(a.property.listPrice)
        ),
    });
  };

  const handleToggleDealNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    dispatch({type: "toggleDealNumber", payload: event.currentTarget});
  };

  const handleDescendingPropertyId = (value: any) => {
    dispatch({
      type: "descendingPropertyId",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) => Number(b.property.id) - Number(a.property.id)
        ),
    });
  };

  const handleAscendingPropertyId = (value: any) => {
    dispatch({
      type: "ascendingPropertyId",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) => Number(a.property.id) - Number(b.property.id)
        ),
    });
  };

  const handleDescendingDealId = (value: any) => {
    dispatch({
      type: "descendingDealId",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) =>
            Number(b.contractProgressId) - Number(a.contractProgressId)
        ),
    });
  };

  const handleAscendingDealId = (value: any) => {
    dispatch({
      type: "ascendingDealId",
      payload: adjustedProperties
        .slice()
        .sort(
          (a: any, b: any) =>
            Number(a.contractProgressId) - Number(b.contractProgressId)
        ),
    });
  };

  const onlyRFPs = () => {
    dispatch({type: "showOnlyRfps"});
  };

  const onlyProperties = () => {
    dispatch({type: "showOnlyProperties"});
  };

  const allDeals = async () => {
    dispatch({type: "setLoading", payload: true});

    await dispatch({
      type: "allDeals",
      payload: {allProperties, allRfps},
    });
  };

  function resolveReferences(
    obj: any,
    lookup: Map<string, any> = new Map()
  ): any {
    if (obj && typeof obj === "object") {
      // Check if object has $id and it's not in lookup map
      if (obj.$id && !lookup.has(obj.$id)) {
        lookup.set(obj.$id, obj); // Add object to lookup map
      }
      // Recursively resolve properties
      for (let key in obj) {
        if (key === "$ref") {
          // Replace $ref with referenced object
          let refId = obj[key];
          if (lookup.has(refId)) {
            obj = {...lookup.get(refId)}; // Create new object to prevent mutation
            delete obj.$id; // Optional: Remove $id from resolved object
          }
        } else {
          obj[key] = resolveReferences(obj[key], lookup); // Recursively resolve nested objects
        }
      }
    }
    return obj;
  }

  useEffect(() => {
    // Set loading to true before starting the fetch operation
    dispatch({type: "setLoading", payload: true});

    agent.Deals.allDeals()
      .then((deals) => {
        let resolvedData = resolveReferences(deals);

        dispatch({
          type: "setDeals",
          payload: resolvedData,
        });

        // Set loading to false after fetching the deals
        dispatch({type: "setLoading", payload: false});
      })
      .catch((error) => {
        console.log(error);

        // Set loading to false if there is an error
        dispatch({type: "setLoading", payload: false});
      });
  }, []);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isActive ? (
              <SearchBarOpen
                onSearchClose={handleToggleSearch}
                onPropertySearch={handleDealSearch}
              />
            ) : (
              <SearchBarClosed onSearchClick={handleToggleSearch} />
            )}
          </Box>

          <Box
            sx={{
              color: "#707070",
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              height: "55px",
            }}
          >
            {isFilterActive ? (
              <DealsFilter
                allDeals={allDeals}
                // Category
                anchorElCategory={anchorElCategory}
                onToggleCategory={handleToggleCategory}
                openCategory={openCategory}
                onlyProperties={onlyProperties}
                onlyRFPs={onlyRFPs}
                //Price
                onTogglePrice={handleTogglePrice}
                anchorElPrice={anchorElPrice}
                openPrice={openPrice}
                onHighToLow={handleHighToLow}
                onLowToHigh={handleLowToHigh}
                //DealNumber
                anchorElDealNumber={anchorElDealNumber}
                openDealNumber={openDealNumber}
                onToggleDealNumber={handleToggleDealNumber}
                onAccendingDealId={handleAscendingDealId}
                onDecendingDealId={handleDescendingDealId}
                //PropertyNumber
                anchorElPropertyNumber={anchorElPropertyId}
                openPropertyNumber={openPropertyId}
                onTogglePropertyNumber={handleTogglePropertyId}
                onAccendingPropertyId={handleAscendingPropertyId}
                onDecendingPropertyId={handleDescendingPropertyId}
                //Close Icon
                onFilterClose={handleToggleFillter}
              />
            ) : (
              <Button
                onClick={handleToggleFillter}
                sx={{
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <FilterListIcon
                  sx={{
                    color: "rgba(112, 112, 112, 1)",
                    "&:hover": {
                      color: "rgba(13, 37, 97, 1)",
                    },
                  }}
                />
              </Button>
            )}
          </Box>
        </Box>

        {loading ? (
          <LoaderWithLinearProgress />
        ) : primaryView ? (
          <DealList
            properties={adjustedProperties}
            loading={loading}
            rfps={adjustedRfps}
          ></DealList>
        ) : onlyRfps ? (
          <ServiceList rfps={adjustedRfps} />
        ) : (
          <>
            <DealList
              properties={adjustedProperties}
              loading={loading}
              rfps={adjustedRfps}
            ></DealList>
            <ServiceList rfps={adjustedRfps} />
          </>
        )}
      </Box>
    </>
  );
};

export default DealPage;
