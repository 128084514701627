import {Box, Card, CardActions, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import VerticalDivider from "../../../app/components/VerticalDividerCard";
import {useAppSelector} from "../../../app/store/configureStore";

interface Props {
  property: any;
  contractId: any;
}

const DealCardBuyerDisabledForLenderOfferView = ({
  property,
  contractId,
}: Props) => {
  const {user} = useAppSelector((state) => state.account);

  const fullAddress = `${property.addressLine} ${property.city}, ${property.state} ${property.postalCode} ${property.country}`;

  return (
    <>
      <Box>
        <Card
          key={property.id}
          sx={{
            border: "1px solid #AACCDD",
            borderRadius: "8px",
            boxShadow: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            width: "100%",
            height: "154px",
            marginBottom: "15px",
            color: "#424242",
          }}
        >
          <CardContent
            sx={{
              width: "17%",
              padding: "0px",
              marginRight: "10px",
              height: "154px",
            }}
          >
            <Link
              to={`/deals/overview/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <img
                src={property.mainImageUrl}
                alt=""
                style={{
                  height: "154px",
                  width: "100%",
                  padding: "0px",
                  margin: "0px",
                }}
              />
            </Link>
          </CardContent>
          <CardActions sx={{width: "15%"}}>
            <Link
              to={`/deals/overview/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography sx={{color: "#424242"}} variant="h5">
                £{property.listPrice?.toLocaleString()}
              </Typography>
              <Typography sx={{lineHeight: "1.2", fontSize: "0.75rem"}}>
                {fullAddress}
              </Typography>

              <Typography variant="subtitle1">#C{property.id}</Typography>
              <Typography variant="subtitle1">Deal#{contractId}</Typography>
            </Link>
          </CardActions>
          <VerticalDivider />
          {user?.profession === "Buyer" ||
          (user?.profession === "Seller" && property.hasLenderOffer) ||
          user?.profession === "Buyer" ||
          (user?.profession === "Seller" && property.hasServiceOffer) ? (
            <Link
              to={`/deals/lenderoffers/${property.propertyId}/${contractId}`}
              style={{
                textDecoration: "none",
                color: "#757575",
                width: "10%",
                height: "100%",
                display: "flex",
                padding: "15px",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FlagIcon
                  sx={{
                    color: "rgba(38, 153, 251, 1)",
                    fontSize: "40px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(97, 97, 97, 1)",
                    font: "Roboto",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Offers
                </Typography>
              </Box>
            </Link>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
                color: "#757575",
                width: "10%",
                height: "100%",
                padding: "15px",
                justifyContent: "center",
              }}
            />
          )}
          <VerticalDivider />

          <Box
            sx={{
              width: "25%",
              height: "100%",
              display: "flex",
              padding: "15px",
              flexDirection: "column",
              marginLeft: "30px",
            }}
          >
            <Link
              to={`/deals/overview/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: "5px",
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#424242",
                }}
              >
                Summary
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Duration of Listing:{property.durationOfListing}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Type:{property.type}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Site Size:{property.siteSize}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Ground Rent:{property.groundRent}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Building Size:±{property.buildingSize}SF
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "20%",
              height: "100%",
              display: "flex",
              padding: "15px",
              flexDirection: "column",
            }}
          >
            <Link
              to={`/deals/overview/${property.id}`}
              style={{textDecoration: "none", color: "#757575"}}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: "23px",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#424242",
                }}
              ></Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Service Charge:{property.serviceCharge}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Built:{property.built}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Tenure:{property.tenure}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Length Of Lease:{property.lengthOfLease}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#616161",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                Parking:{property.parking}
              </Typography>
            </Link>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default DealCardBuyerDisabledForLenderOfferView;
