import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {Box, Button} from "@mui/material";
import {PropsWithChildren, useEffect, useState} from "react";
import ConfirmDateTimeAvailability from "./ConfirmDateTimeAvailability";
import agent from "../api/agent";
import ConfimedScheduleDate from "./ConfimedScheduleDate";
import {useAppSelector} from "../store/configureStore";

interface Props extends PropsWithChildren {
  value: dayjs.Dayjs | null;
  onSetValue: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  contractProgress: any;
  contractProgressFirstVersionForDateSetter: any;
  confirmDateList: never[];
}

export default function DateTimePickerValue({
  value,
  onSetValue,
  contractProgress,
  contractProgressFirstVersionForDateSetter,
  confirmDateList,
}: Props) {
  const [firstTimeSlotConfirm, setFirstTimeSlotConfirm] = useState(false);
  const [secondTimeSlotConfirm, setSecondTimeSlotConfirm] = useState(false);
  const [thirdTimeSlotConfirm, setThirdTimeSlotConfirm] = useState(false);
  const [notAttendingConfirm, setNotAttendingConfirm] = useState(false);
  const {user} = useAppSelector((state) => state.account);

  let cpid = contractProgress.mvpPhases.$values[0].contractProgressId;

  useEffect(() => {
    agent.ContractProgressDeal.getConfirmDate(cpid).then((response) => {
      if (response.confirmedFirstDateTimeSlotForPropertyVisit === true) {
        setFirstTimeSlotConfirm(true);
      }
      if (response.confirmedSecondDateTimeSlotForPropertyVisit === true) {
        setSecondTimeSlotConfirm(true);
      }
      if (response.confirmedThirdDateTimeSlotForPropertyVisit === true) {
        setThirdTimeSlotConfirm(true);
      }
      if (response.notAttending === true) {
        setNotAttendingConfirm(true);
      }
    });
  }, [cpid]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        overflowY: "scroll",
        height: "69.5vh",
        width: "385px",
        padding: "10px",
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {firstTimeSlotConfirm === false &&
      secondTimeSlotConfirm === false &&
      thirdTimeSlotConfirm === false &&
      notAttendingConfirm === false ? (
        <>
      {(user?.profession === "PropertySurveyor" ||
        user?.profession === "PropertyValuator" ||
        user?.profession === "Buyer") && (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Make a schedule"
                value={value}
                onChange={(newValue) => onSetValue(newValue)}
                format="DD/MM/YYYY hh:mm A" // This sets the display format
              />
            </LocalizationProvider>

            <Button
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                textAlign: "center",
                lineHeight: "36px",
                minWidth: "100px",
                height: "40px",
                margin: "15px",
              }}
              type="submit"
            >
              Submit
            </Button>
          </>
      )}

          <ConfirmDateTimeAvailability
            contractProgressFirstVersionForDateSetter={
              contractProgressFirstVersionForDateSetter
            }
            confirmDateList={confirmDateList}
            onSetFirstTimeSlotConfim={setFirstTimeSlotConfirm}
            onSetSecondTimeSlotConfirm={setSecondTimeSlotConfirm}
            onSetThirdTimeSlotConfirm={setThirdTimeSlotConfirm}
            onSetNotAttendingConfirm={setNotAttendingConfirm}
          />
        </>
      ) : (
        <ConfimedScheduleDate
          firstTimeSlotConfirm={firstTimeSlotConfirm}
          secondTimeSlotConfirm={secondTimeSlotConfirm}
          thirdTimeSlotConfirm={thirdTimeSlotConfirm}
          notAttendingConfirm={notAttendingConfirm}
          confirmDateList={confirmDateList}
          contractProgressFirstVersionForDateSetter={
            contractProgressFirstVersionForDateSetter
          }
        />
      )}
    </Box>
  );
}
